<!-- MainDashboard.vue Start -->
<!-- eslint-disable -->

<template>
<v-container fluid class = "pt-0 pl-0 pr-0">
<v-row no-gutters  class = "pt-1 pb-2 pl-0 ma-0 pr-0 black">  
<v-col cols = "5">
 <!-- <v-sheet id="card-1" elevation=0 
    max-width='500px' class = "pt-1 pb-0 pl-0 ma-0 black md-2 rounded-0" > -->
   <v-card-text class="text-h4 yellow--text font-weight-medium pt-0 pb-0 ma-0">
   Last Refresh:
   </v-card-text>   
   <v-card-text class="text-h3 white--text pt-0 pb-2 ma-0">
   {{lastrefresh}}
     </v-card-text> 
   </v-col>
  <v-col align="right" align-self="center" >
 <v-btn
   color="red"
   dark
   small
   class=" mr-4 text subtitle1 font-weight-regular">
   Refresh Data
 </v-btn>
 <v-btn
   color="green"
   dark
   small
   class="mr-4">
   Select Date
 </v-btn>
</v-col>
</v-row>

<!-- *********** WATER WELL ROW HERE ************ -->
<v-container
class="blue lighten-4 ml-0"
>
<v-row class="flex-child mr-0"
>
  <v-col cols="4">
    <v-card-text class="text-h2 black--text font-weight-bold pt-0 pa-0 ">
    Water Well
    </v-card-text>   
    <v-card
      color="blue darken-2" class="d-flex" elevation="1" height="150" >
      <v-card-text class="text-h3 white--text font-weight-light pt-0 pa-0 ml-2 mt-1">
    <span class="dot-red" ></span>&nbsp;<span style="font-weight:400; color: yellow;" >Level: </span>91’ / 32% filled <br>
    <span class="dot-red" ></span>&nbsp;<span style="font-weight:400; color:yellow;">Volume: </span> 126 gal <br>
    <span class="dot-red" ></span>&nbsp;<span style="font-weight:400; color:yellow;">Outflows:</span> 242 gal <br>
    <span class="dot-yellow" ></span>&nbsp;<span style="font-weight:400; color:yellow;">Recovery:</span> 33 gal
    </v-card-text>  
    </v-card>
    <v-card-text class="text-h4"><span class="font-weight-bold">Last observed well level: </span> 04/13/22, 100% filled</v-card-text>
    
</v-col>
<!-- *********** WATER WELL CHART1 HERE ************ -->
  <v-col cols="4">
    <bar-chart></bar-chart>
  </v-col>
<!-- *********** WATER WELL CHART2 HERE ************ -->
  <v-col cols="4">
    <bar-chart2></bar-chart2>
  </v-col>
</v-row>
</v-container>

<v-container
class="amber lighten-4 ml-0"
>
<!-- *********** PROPANE CHART1 HERE ************ -->
<v-row>
  <v-col cols="4">
   <v-card-text class="text-h2 black--text font-weight-bold pa-0 ma-0">
   Propane Tank
   </v-card-text>   
  <v-card color="amber darken-4" class="d-flex" elevation="1" height="150"
  >
   <v-card-text class="text-h3 white--text font-weight-light pt-0 pa-0 ml-2 mt-1">
    <span class="dot-green" ></span>&nbsp;<span style="font-weight:400; color: yellow;" >Level: </span> 72% filled <br>
    <span class="dot-green" ></span>&nbsp;<span style="font-weight:400; color:yellow;">Volume: </span> 280 gal <br>
    <span class="dot-green" ></span>&nbsp;<span style="font-weight:400; color:yellow;">Consumed:</span> .7 gal <br>
    </v-card-text>   
    </v-card>
    <v-card-text class="text-h4"><span class="font-weight-bold">Last Fill:</span> 03/23/22, 72.5 gal</v-card-text>
  </v-col>
<!-- *********** PROPANE CHART1 HERE ************ -->
  <v-col cols="4">
    <bar-chart3></bar-chart3>
  </v-col>
<!-- *********** PROPANE CHART2 HERE ************ -->
  <v-col cols="4">
    <bar-chart4></bar-chart4>
  </v-col>
</v-row>
</v-container>

</v-container>
</template>
<!-- END OF TEMPLATE -->
<script>

/* eslint-disable */
//************************************************************************
// JAVASCRIPT
//************************************************************************
import VerticalComboChart3 from '../../components/charts/VerticalComboChart3';
import BarChart from "./BarChart.vue";
import BarChart2 from "./BarChart2.vue";
import BarChart3 from "./BarChart3.vue";
import BarChart4 from "./BarChart4.vue";
// import Chart from "chart.js";
export default {
  name: 'maindashboard',
  components: {VerticalComboChart3, BarChart, BarChart2, BarChart3, BarChart4},
//************************
// DATA
//************************
  data () {  return {
  lastrefresh: 'Wed, 5/5/2022 10:55am'
}

  } } // End of data () {  return {
</script>

<style scoped>
.v-btn {font-size: 1rem;}
.dot-green {
height: 25px;
  width: 25px;
  background-color: #4CAF50;
  border-radius: 50%;
  display: inline-block;}
.dot-red {
height: 25px;
  width: 25px;
  background-color: red;
  border-radius: 50%;
  display: inline-block;}
.dot-yellow {
height: 25px;
  width: 25px;
  background-color: yellow;
  border-radius: 50%;
  display: inline-block;}
</style>