<script>
/* eslint-disable */
import { Bar } from "vue-chartjs";

export default {
  name: 'VerticalComboChart3',
  extends: Bar,
  data () { 
    return {
    }
  },
  props: {
    ChartLabels: {
      type: Array
    },
    barChart1Label: {
      type: String
    },
    barChart1AxisId: {
      type: String
    },
    barChart1Hidden: {
      type: Boolean
    },
    barChart1Datax: {
      type: Array
    },
    barChart1Datay: {
      type: Array
    },
    lineChart1Label: {
      type: String
    },
    lineChart1AxisId: {
      type: String
    },
    lineChart1Hidden: {
      type: Boolean
    },
    lineChart1Datax:{
      Array
    },
    lineChart1Datay:{
      Array
    },
    lineChart2Label: {
      type: String
    },
    lineChart2AxisId: {
      type: String
    },
    lineChart2Hidden: {
      type: Boolean
    },
    lineChart2Datax:{
      Array
    },
    lineChart2Datay:{
      Array
    },
    yAxisLeft:{
      type: Boolean
    },
    yAxisRight: {
      type: Boolean
    },
    yAxisLeftLabel: {
      type: String
    },
    yAxisRightLabel: {
      type: String
    } 
    
    },
  mounted() {
    const {
      borderColor,
      pointBorderColor,
      pointBackgroundColor,
      backgroundColor
    } = {
      borderColor: "#251F47",
      pointBorderColor: "#260F26",
      pointBackgroundColor: "#03a9f0",
      backgroundColor: "#03a9f0"
    }


    this.renderChart(
      { // Data to render
        labels: this.ChartLabels,
        datasets: [
          {
            type: 'line',
            yAxisID: this.lineChart1AxisId,
            label: this.lineChart1Label,
            data: this.lineChart1Datay,
            borderColor: "#8e5ea2",
            backgroundColor: "#8e5ea2",
            fill: true,
            hidden: this.lineChart1Hidden
          },
          {
            type: 'line',
            yAxisID: this.lineChart2AxisId,
            label: this.lineChart2Label,
            data: this.lineChart2Datay,
            borderColor: "#0B0B0B",
            backgroundColor: "#0B0B0B",
            fill: true,
            hidden: this.lineChart2Hidden
          },
          {
            type: 'bar',
            label: this.barChart1Label,
            yAxisID: this.barChart1AxisId,
            data: this.barChart1Datay,
            borderColor: "#0B0B0B",
            pointBorderColor: pointBorderColor,
            pointBackgroundColor: pointBackgroundColor,
            backgroundColor: backgroundColor,
            hidden: this.barChart1Hidden
          },
        ]
      },
      { // Options object
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          position: 'bottom'
        },
        scales: {
          xAxes: [{
            display: true,
            ticks: {
              beginAtZero: true
            }
          }],
          yAxes: [{
            id:"idleft",
            display: true,
            position: 'left',
            type: 'linear',
            scaleLabel: {
              display: true,
              labelString: this.yAxisLeftLabel,
              beginAtZero: true,
              } 
            },{
            id:"idright",
            display: this.yAxisRight,
            position: 'right',
            type: 'linear',
            scaleLabel: {
              display: true,
              labelString: this.yAxisRightLabel,
              beginAtZero: true,
              }
            }
            // ticks: {
            //   callback: (value, index, values) => {
            //     return value.toString().substr(0,3)
            //   }
            // }
          ] },
        tooltips: {
          callbacks: {
            title: function(tooltipItem, data) {
              // console.log("VerticalBarChart callback: tooltipItem =  ", tooltipItem, " data = ", data)
              // let xl = 'Hour ' + tooltipItem[0].yLabel + ':00 thru ' + parseInt(tooltipItem[0].yLabel) + ':59'
              // let xl
              // let dsindex = tooltipItem[0].datasetIndex
              // let itemindex = tooltipItem[0].index
              // if (dsindex > 0) xl = tooltipItem[0].label
              let xl = tooltipItem[0].label
              // else xl = "Previous Week"
              return xl
            },
            label: function(tooltipItem, data) {
              let yv = Number(tooltipItem.value)
              let ytip = yv.toFixed(1) + ' gal'
              return ytip
            }
          }
        }
      }
    );
  }
};
</script>
