<!-- eslint-disable -->

<template>
  <v-card class="card-shadow border-radius-xl mt-0 mb-0 z-index-2">
    <div class="px-4 pt-2 pb-0 d-flex">
        <p class="font-weight-light text-h4 text-md mb-0">
          Consumption (gal)        </p>
      </div>
    <div class="card-padding pt-0 px-4">
      <div class="chart">
        <canvas id="bar-chart4" class="chart-canvas" height="300"></canvas>
      </div>
    </div>
  </v-card>
</template>

<script>
/* eslint-disable */
//***************************************************************
// JAVASCRIPT
//***************************************************************
import Chart from "chart.js";

export default {
  name: "bar-chart4",
  data: function () {
    return {
      barChartId: "bar-chart4",
    };
  },
  mounted() {
    new Chart(document.getElementById(this.barChartId).getContext("2d"), {
      type: "bar",
      data: {
        labels: ["5/1/22", "5/2/22", "5/3/22", "5/4/22", "5/5/22", "5/6/22", "5/7/22"],
        datasets: [
          {
            label: "Consumption",
            weight: 5,
            borderWidth: 0,
            borderRadius: 4,
            backgroundColor: "#E91E63",
            data: [.55, .42, .65, 1.2, .7, 0, 0],
            fill: false,
            maxBarThickness: 35,
          },
          
        ],
      },
      //  ***********************
      options: {
        responsive: true,
        maintainAspectRatio: false,
          legend: {
            position: 'bottom'
        },
        scales: {
          xAxes: [{
            display: true,
            ticks:{
              beginAtZero: true
              }
          }],
          yAxes: [{
            display: true,
            position: 'left',
            type: 'linear',
            ticks:{
              beginAtZero: true
              },
            scaleLabel: {
              display: false,
              labelString: 'gallons',
              } },] 
            }, 
          }
      } )
  },
}
</script>
      //     y: {
      //       grid: {
      //         drawBorder: false,
      //         display: true,
      //         drawOnChartArea: true,
      //         drawTicks: false,
      //         borderDash: [5, 5],
      //         color: "#c1c4ce5c",
      //       },
      //       ticks: {
      //         display: true,
      //         padding: 10,
      //         color: "#8e5ea2",
      //         font: {
      //           size: 14,
      //           weight: 300,
      //           family: "Roboto",
      //           style: "normal",
      //           lineHeight: 2,
      //         },
      //       },
      //     },
      //     x: {
      //       grid: {
      //         drawBorder: false,
      //         display: false,
      //         drawOnChartArea: true,
      //         drawTicks: true,
      //         color: "#c1c4ce5c",
      //       },
      //       ticks: {
      //         display: true,
      //         color: "#9ca2b7",
      //         padding: 10,
      //         font: {
      //           size: 14,
      //           weight: 300,
      //           family: "Roboto",
      //           style: "normal",
      //           lineHeight: 2,
      //         },
      //       },
      //     },
      //   },
      // },
